import React from "react";

import { SEO } from "../components";
import OkendoReviewsRating from "../components/Reviews/Ratings";
import OkendoReviewsWidget from "../components/Reviews/Widget";
import withLayout from "../hoc/withLayout/withLayout";

function Reviews() {
  const reviewsCollectionID = "8961e7c5-6cd0-43a2-9968-4dd6e71ac8c9"; // TODO: REPLACE WITH GENERAL COLLECTION
  return (
    <>
      <SEO title="Reviews" />
      <div>
        <div className="component-default-padding">
          <h1 className="h1-styles mb-5 text-center">Reviews</h1>
          <p className="p-styles mb-3 text-center">
            These verified reviews all come from people who actually purchased
            from Outer.
          </p>
          <div className="mb-12 text-center">
            <OkendoReviewsRating collectionID={reviewsCollectionID} />
          </div>
          <OkendoReviewsWidget />
        </div>
      </div>
    </>
  );
}

export default withLayout(Reviews);
