import React, { useEffect, useRef } from "react";

import OkendoAssets from "../../components/OkendoAssets/OkendoAssets";

interface OkendoReviewWidgetProps {
  hasAggregate?: boolean;
  collectionID?: string;
  productID?: string;
}

export default function OkendoReviewsWidget({
  collectionID,
  productID,
}: OkendoReviewWidgetProps) {
  if (collectionID) {
    collectionID = "8961e7c5-6cd0-43a2-9968-4dd6e71ac8c9";
  }

  if (productID) {
    productID = "shopify-4348225880124";
  }

  const widgetContainer = useRef(null);

  const initialiseReviewsWidget = () => {
    window.okeWidgetApi.initWidget(widgetContainer.current).the;
  };

  useEffect(() => {
    if (window.okeWidgetApi) {
      initialiseReviewsWidget();
    } else {
      window.addEventListener("oke-script-loaded", initialiseReviewsWidget);
    }
  }, []);

  return (
    <div>
      <OkendoAssets />
      <div
        ref={widgetContainer}
        data-oke-widget
        data-oke-reviews-collection-id={collectionID}
        data-oke-reviews-product-id={productID}
      ></div>
    </div>
  );
}
