import React, { useEffect, useRef } from "react";

import OkendoAssets from "../../components/OkendoAssets/OkendoAssets";

interface Params {
  collectionID: string;
}

export default function OkendoReviewsRating({ collectionID }: Params) {
  const widgetContainer = useRef(null);

  const initialiseReviewsWidget = () => {
    window.okeWidgetApi.initWidget(widgetContainer.current);
  };

  useEffect(() => {
    if (window.okeWidgetApi) {
      initialiseReviewsWidget();
    } else {
      window.addEventListener("oke-script-loaded", initialiseReviewsWidget);
    }
  }, [collectionID]);

  if (collectionID === undefined) {
    //collectionID = "8961e7c5-6cd0-43a2-9968-4dd6e71ac8c9";
    return null;
  }

  return (
    <>
      <OkendoAssets />
      <div
        ref={widgetContainer}
        data-oke-star-rating
        data-oke-reviews-group-id={collectionID}
      ></div>
    </>
  );
}
